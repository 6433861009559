<template>
  <v-row justify="center">
    <v-col xs="12" sm="7" md="5" lg="4" xl="3">
      <v-card class="ma-3 mt-8">
        <v-card-title>
          <span class="headline">
            Mot de passe perdu ?
          </span>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-alert v-if="success" type="success">
            Si l'adresse e-mail correspond à un compte, un e-mail vous a été envoyé.
          </v-alert>
          <v-form
            v-else
            @submit.prevent="onSubmit">
            <v-text-field
              outlined
              v-model="email"
              label="Email"
              type="email"
              name="email"
              :rules="[v => !!v || 'Email obligatoire']"
            />
            <v-btn
              class="mt-2"
              block
              type="submit"
              color="primary"
              :loading="loading"
              :disabled="email === ''"
            >
              Valider
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    email: '',
    success: false,
    loading: false
  }),
  created () {},
  methods: {
    onSubmit () {
      this.success = false
      this.loading = true
      this.$apitator.post('/auth/reset-password/request', { email: this.email }).then(() => {
        this.loading = false
        this.success = true
      }).catch(err => {
        this.loading = false
        console.error(err)
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Impossible de demander une réinitialisation à l'heure actuelle, veuillez reesayer plus tard"
        })
      })
    }
  }
}
</script>
